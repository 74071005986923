.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0rem;
  color: white;
  position: relative;
  margin-top: 10px;
}

.brand h1 {
  margin: 0;
  font-size: 3rem;
  font-weight: bold;
}

.menu-icon {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.nav-links {
  list-style-type: none;
  display: flex;
  gap: 1.5rem;
  transition: transform 0.3s ease;
}

.nav-links.open {
  transform: translateX(0);
}

.nav-links li {
  display: inline;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #00ff00;
  text-underline-offset: 6px;
}

.contact-button {
  background-color: #00ff00;
  color: #1b1c1e;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "Fira Code", monospace;
  font-weight: bold;
  border: #00ff00 solid 2px;
}

.contact-button span {
  color: black;
  text-decoration: none;
}

.contact-button:hover {
  background-color: transparent;
  text-decoration: none;
  border: #00ff00 solid 2px;
}

.contact-button:hover > span {
  color: #00ff00;
  text-decoration: none;
}

.nav-links a.active {
  color: #00ff00; /* Same as the hover color */
  text-underline-offset: 6px;
}

.contact-button.active {
  background-color: transparent;
  border: #00ff00 solid 2px;
}

.contact-button.active > span {
  color: #00ff00;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
    color: white;
  }

  .nav-links {
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 250px;
    background-color: #1b1c1e;
    padding-top: 5rem;
    transition: right 0.3s ease;
    z-index: 1000;
  }

  .nav-links.open {
    right: 0;
  }

  .nav-links li {
    width: 100%;
    margin-bottom: 1rem;
  }

  .nav-links a {
    display: block;
    text-align: left;
    padding: 1rem;
    font-size: 1.2rem;
  }
}
