.education-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
}

.education-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  /* background-color: #f9f9f9; */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  animation: float 3s ease-in-out infinite; /* Floating animation */
}

/* Image container styling */
.edu-img-container {
  flex-shrink: 0;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out; /* Smooth transition for hover scaling */
}

.edu-img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out; /* Smooth transition for hover scaling */
}

/* Hover effect - scale the image */
.edu-img-container:hover .edu-img {
  transform: scale(1.2); /* Scale up image on hover */
}

/* Floating animation */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Assign different animation delays for each education item */
.education-item:nth-child(1) {
  animation-delay: 0s;
}

.education-item:nth-child(2) {
  animation-delay: 0.5s;
}
