/* services.css */

.service-item {
    transition: transform 0.3s ease;
    position: relative;
    animation: float 3s ease-in-out infinite;
  }
  
  /* Different animation delays for each service item */
  .service-item:nth-child(1) {
    animation-delay: 0s;
  }
  
  .service-item:nth-child(2) {
    animation-delay: 0.5s;
  }
  
  .service-item:nth-child(3) {
    animation-delay: 1s;
  }
  
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
  }
}
  
  .service-item:hover {
    transform: translateY(-15px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  