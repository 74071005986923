.swiper_container {
  height: 520px;
  margin: 50px 50px 0 50px;
  position: relative;
  background-color: #1a1a1a; /* Dark background for contrast */
  padding: 20px 0;
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); */
  animation: float 3s ease-in-out infinite;
}

.swiper-slide {
  width: 370px;
  height: 420px;
  position: relative;
  margin: 0 0.25rem;
  overflow: hidden;
   /* border: 4px solid #fff; White border for film frame */
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Shadow to make it pop */
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%); /* Grayscale for film roll effect */
  transition: filter 0.3s ease; /* Transition to full color */
}

.swiper-slide-active img {
  filter: grayscale(0); /* Full color on active slide */
}

.swiper-slide::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 6px;
  top: -6px;
  background: repeating-linear-gradient(
    to right,
    transparent,
    transparent 10px,
    #000 10px,
    #000 15px
  ); /* Top perforation dots */
}

.swiper-slide::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 6px;
  bottom: -6px;
  background: repeating-linear-gradient(
    to right,
    transparent,
    transparent 10px,
    #000 10px,
    #000 15px
  ); /* Bottom perforation dots */
}

@media (max-width: 500px) {
  .swiper_container {
    height: 470px;
  }
  .swiper-slide {
    width: 280px !important;
    height: 360px !important;
    margin: 0 0.125rem !important;
  }
  .swiper-slide img {
    width: 280px !important;
    height: 360px !important;
  }
}

.slider-controler {
  position: relative;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 20px;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative;
  width: 150px !important;
  bottom: 10px;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}
/* Achievements.css */



@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Adjust this value for more/less floating */
  }
  100% {
    transform: translateY(0);
  }
}


