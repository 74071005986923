/* Neon border animation on hover */
.neon-border {
  position: relative;
  padding: 1px;
  border-radius: 8px;
}

.neon-border::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 8px;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.neon-border:hover::before {
  opacity: 1;
  background: linear-gradient(270deg, #00ff00, #00ccff, #00ff00, #ff00ff);
  background-size: 400% 400%;
  animation: neon-border-animation 2s linear infinite;
}

@keyframes neon-border-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
